import jQuery from 'jquery';
const $ = jQuery;

window.jQuery = $;
window.$ = $;


import 'cssuseragent';
import 'jquery.easing';

import Logo from './module/Logo';
import LogoTq from './module/LogoTq';
import Menu from './module/Menu';
import Modal from './module/Modal';
import PagePro2 from './module/PagePro2';
import PageKvtest from './module/PageKvtest';
import PageSingle from './module/PageSingle';

class MainApp {
  constructor() {
    if(this.isTouchDevice()) {
      $('html').addClass('is-touch-device');
      $('a').attr('data-touch', 'true');
      $('button').attr('data-touch', 'true');
    } else {
      $('html').addClass('is-mouse-device');
    }

    this.$window              = $(window);
    this.windowWidth = this.$window.width();
    this.windowHeight = this.$window.height();
    this.scrollTop = 0;

    this.minWindowHeight = window.innerHeight;
    // this.spMenu = new SpMenu();
    this.menu = new Menu(this);


    this.prevViewMode = '';
    this.isHome = false;
    if($('body').hasClass('home')) {
      this.isHome = true;
    }

    $('.BtnToggleMenu').on('click', () => {
      if(this.menu.isFadein) {
        this.menu.close();
      } else {
        this.menu.open();
      }
    });

    if($('.Modal').length) {
      this.modal = new Modal(this);
      $('.js-open-modal').on('click', () => {
        if(this.modal.isFadein) {
          this.modal.close();
        } else {
          this.modal.open();
        }
        return false;
      });
    }


    // this.router = new Router(this);
    this.inviewObject = [];
  }

  isTouchDevice() {
    return ('ontouchstart' in window || navigator.msPointerEnabled) ? true : false;
  }

  waitLoading() {
    this.svgLoadInterval = setInterval(() => {
      if(window.onLoadCompleteSvg) {
        clearInterval(this.svgLoadInterval);
        this.init();

      }
    }, 1000 / 30);
  }

  init() {
    /*
    this.isTablet             = false;
    this.onLoadComplete       = true;
    this.isShowProjectFooter = true;
    this.imgLazyLoad          = new ImgLazyLoad(this);

    $('.BtnBackToTop').on('click', () => {
      this.scrollPage(0);
    });
    */

    if($('.Logo').length) {
      this.logo = new Logo();
    }

    if($('.LogoTq').length) {
      this.logoTq = new LogoTq();
    }


    if(
      $('body').hasClass('single')
      || $('body').hasClass('page-template-page-privacy')
      || $('body').hasClass('page-template-page-common')
      || $('body').hasClass('page-template-page-contact')
      || $('body').hasClass('page-template-page-results')
    ) {
      this.pageApp = new PageSingle(this);
    } else if($('body').hasClass('page-template-page-kv-test')) {
      this.pageApp = new PageKvtest(this);
    } else {
      this.pageApp = new PagePro2(this);
    }

    $('.wpcf7-form-control.wpcf7-confirm').on('click', () => {
      // $('.Form').find('input, textarea, select').prop('readonly', true);
      // $('.wpcf7-form-control.wpcf7-submit').removeClass('wpcf7c-force-hide');

      // wpcf7-form-control wpcf7-submit wpcf7c-elm-step2 wpcf7c-force-hide
    });

    $('.wpcf7-form-control.wpcf7-back').on('click', () => {
      $('.wpcf7-form').removeClass('custom-wpcf7c-confirmed');
      $('.wpcf7-form').attr('data-status', 'init');
      // $('.Form').find('input, textarea, select').removeAttr('readonly');
      // $('.wpcf7-form-control.wpcf7-submit').addClass('wpcf7c-force-hide');
    });


    this.scrollableElement = null;
    let firefox = navigator.userAgent.match(/Firefox\/([0-9]+)\./);
    if ('scrollingElement' in document) {
      this.scrollableElement = document.scrollingElement;
    } else if (/*@cc_on!@*/false || (!!window.MSInputMethodContext && !!document.documentMode)) {
      this.scrollableElement = document.documentElement;
    } else if (firefox && parseInt(firefox[1]) <= 47) {
      this.scrollableElement = document.documentElement;
    } else {
      this.scrollableElement = document.body;
    }


    $('.BtnBackToTop').on('click', () => {
      this.scrollPage(-1);
      return false;
    });
    $('.js-back-to-top').on('click', () => {
      this.scrollPage(-1);
      return false;
    });

    this.$window
      .on('resize', ()=>{
        this.onWindowResize();
      })
      .on('scroll', ()=>{
        this.onWindowScroll();
      });
    this.onWindowResize();

    /*
    let onClickMenu = (e) => {
      if($(e.currentTarget).attr('href').indexOf('#') != -1) {
        let hash = $(e.currentTarget).attr('href').slice($(e.currentTarget).attr('href').indexOf('#'));
        hash = hash.slice(1);
        let targetUrl = $(e.currentTarget).attr('href').slice(0, $(e.currentTarget).attr('href').indexOf('#'));

        if(String(window.location.href).indexOf(targetUrl) != -1) {
          $('.BottomContents').stop(false).css('opacity', 0);
          // アンカー遷移
          this.menu.close();

          if(hash == 'news') {
            this.scrollPage(0, true);
            $('.BottomContents').css('opacity', 1);
          } else {
            let $targetSection = $('.ScrollSection[data-id="' + hash + '"]');
            let targetPaddingTop = parseInt($targetSection.find('.ScrollSectionWindow').css('padding-top'));
            this.scrollPage($targetSection.offset()['top'] + targetPaddingTop, true);
            $('.BottomContents').animate({'opacity':1}, 300, 'easeInOutSine');
          }


          return false;
        }
      }
    };
    */

    /*
    $('.MenuSectionListItem').on('click', (e) => {
      onClickMenu(e);
    });
    */
    $('.BtnMenuListItem').on('click', (e) => {
      if($(e.currentTarget).attr('href').indexOf('#') != -1) {
        let hash = $(e.currentTarget).attr('href').slice($(e.currentTarget).attr('href').indexOf('#'));
        hash = hash.slice(1);
        let targetUrl = $(e.currentTarget).attr('href').slice(0, $(e.currentTarget).attr('href').indexOf('#'));

        if(String(window.location.href).indexOf(targetUrl) != -1) {
          $('.BottomContents').stop(false).css('opacity', 0);
          // アンカー遷移
          this.menu.close();

          if(hash == 'news') {
            this.scrollPage(0, true);
            $('.BottomContents').css('opacity', 1);
          } else {
            let $targetSection = $('.ScrollSection[data-id="' + hash + '"]');
            let targetPaddingTop = parseInt($targetSection.find('.ScrollSectionWindow').css('padding-top'));
            this.scrollPage($targetSection.offset()['top'] + targetPaddingTop, true);
            $('.BottomContents').animate({'opacity':1}, 300, 'easeInOutSine');
          }


          return false;
        }
      }
    });


    $('body').css('opacity', 1);


    if($('body').hasClass('home')) {
      $('.Loading').delay(2200).animate({'opacity':0}, 1000, 'easeInOutExpo', () => {
        $('.BtnToggleMenu').addClass('BtnToggleMenu--fadein');
        $('.Loading').css('display', 'none');

        $('body').addClass('is-fadein-complete');
        window.isCancelScroll = false;
        if(this.pageApp != undefined) {
          this.pageApp.start();
        }
        /*
        this.logo.fadein(() => {
          $('.BtnToggleMenu').addClass('BtnToggleMenu--fadein');

          $('body').addClass('is-fadein-complete');

          if(this.pageApp != undefined) {
            this.pageApp.start();
          }
        });
        */
      });

      const revealCover = () => {
        const cover = document.querySelector(".Cover");
        cover.classList.add("revealed");
        setTimeout(() => {
          document.body.style.overflowY = "auto";
        }, 800);
      }
      document.querySelector("#CoverClose").addEventListener("click", () => {
        revealCover();
      });
      document.querySelector("#CoverModalBg").addEventListener("click", () => {
        revealCover();
      });
    } else {
      setTimeout(() => {
        this.logo.fadein(() => {
          $('.BtnToggleMenu').addClass('BtnToggleMenu--fadein');

          $('body').addClass('is-fadein-complete');

          if(this.pageApp != undefined) {
            this.pageApp.onWindowScroll(this.scrollTop);
            this.pageApp.start();
          }
        });
      }, 500);
    }

    if(location.hash.length < 2) {
      this.scrollPage(0, true);
    }
  }

  scrollPage(val, isCancelTransition = false) {
    if(isCancelTransition) {
      $(this.scrollableElement).stop(false).scrollTop(val);
    } else {
      $(this.scrollableElement).stop(false).animate(
        {'scrollTop': val},
        500,
        'easeOutExpo'
      );
    }
  }

  onWindowScroll() {
    this.scrollTop = this.$window.scrollTop();
    if(this.scrollTop < 0) this.scrollTop = 0;

    if(this.pageApp != undefined) {
      this.pageApp.onWindowScroll(this.scrollTop);
    }

    if(this.scrollTop != this.prevScrollTop) {
      if(this.scrollTop > this.prevScrollTop) {
        this.scrollDiff = 1;
      } else {
        this.scrollDiff = -1;
      }
    }

    this.prevScrollTop = this.scrollTop;
  }


  onWindowResize() {
    this.windowWidth = this.$window.width();
    this.windowHeight = this.$window.height();

    if($('html').hasClass('ua-desktop')) {
      $('.WindowHeightContents').css('height', window.innerHeight);
    } else {
      $('.WindowHeightContents').css('height', this.minWindowHeight);

      $('.Layout--top-pro').css('padding-top', '180px');
    }

    if(this.pageApp != undefined) {
      this.pageApp.onWindowResize();
    }


    if($('.TopSectionTitle--flex-position').length > 0) {
      let $dataList = $('.TopSectionTitle--flex-position').next('.DataList');
      let minWidth = 0;
      $dataList.find('.DataListRow__label__width').each((i, e) => {
        if(minWidth < $(e).width()) {
          minWidth = $(e).width();
        }
      });
      $('.TopSectionTitle--flex-position').css('transform', 'translate(-' + String(Math.floor(minWidth)) + 'px, 0)');
    }

    this.prevViewMode = this.viewMode;

    this.onWindowScroll();
  }

  onLoad() {
  }
}

window.onLoadCompleteSvg = true;


let mainApp = new MainApp();
window.isCancelScroll = true;

if(document.body.classList.contains('home')) {
  if(String(document.documentElement.className).indexOf('ua-firefox') != -1) {
    window.addEventListener("DOMMouseScroll", (e) => {
      if(window.isCancelScroll) {
        e.preventDefault();
      }
    }, { passive: false });
  } else {
    document.addEventListener('mousewheel' , (e) => {
      if(window.isCancelScroll) {
        e.preventDefault();
      }
    }, {passive: false});
  }

  document.addEventListener('touchmove', (e) => {
    if(window.isCancelScroll) {
      e.preventDefault();
    }
  }, {passive: false});
}

$('document').ready(() => {

  /*
  let textareaMinHeight = $("textarea").height();
  console.log('textareaMinHeight', textareaMinHeight);
  $("textarea").attr("rows", 1).on("input", (e) => {
    if(textareaMinHeight < e.target.scrollHeight) {
      $(e.target).height(0).innerHeight(e.target.scrollHeight);
    } else {
      $(e.target).height(0).innerHeight(textareaMinHeight);
    }
  });
  */

  $('.wpcf7-form-control-wrap.contact-category').append('<span class="icon-select"></span>');

  if(!$('ua-desktop')) {
    $('.Loading').css('height', window.innerHeight);
  }

  $(document).on('touchstart', 'a, button', (e) => {
    $(e.currentTarget).attr('data-touch', 'true');
  });

  $(document).on('mouseleave', 'a, button', (e) => {
    $(e.currentTarget).removeAttr('data-touch');
  });
});

$(window).on('load', () => {
  mainApp.waitLoading();
  // mainApp.onLoad();
});


